import React from 'react'
import Layout from '../layouts'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import CommonHero from '../components/commonhero/commonhero.js'

class AboutPage extends React.Component {
  render() {
    const title = {
      title: `Your Neighborhood Expert in Colorado Springs, CO`,
    }
    return (
      <Layout head={title} pageTitle="About Us">
        <CommonHero pageTitle="About Us" />
        <main className="bg-white">
          <div className="contact-section py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-5 mb-4">
                  <LazyLoadImage
                    className="img-fluid"
                    effect="blur"
                    src="/img/brian-boals.jpg"
                  />
                  <LazyLoadImage
                    className="img-fluid"
                    effect="blur"
                    src="/img/brian-boals-family.jpg"
                  />
                </div>
                <div className="col-md-7">
                  <div className="intro mb-4">
                    <h2>Brian Boals</h2>
                    <h5>Team Leader / Associate Broker</h5>
                  </div>
                  <p>
                    My mission at the Brian Boals Team is to provide you with
                    excellent results and exemplary service. I am committed to
                    taking the greatest care of you and your referrals with
                    unmatched tenacity and professionalism.
                  </p>
                  <p>
                    I have over 20 years of experience as a RE/MAX agent and am
                    a native of Colorado Springs. With a proven track record of
                    successful marketing and extensive knowledge of the real
                    estate marketplace, we have the expertise to outperform our
                    competition. It gives us great satisfaction to be entrusted
                    with your goals and to help them be fulfilled. Whether
                    buying or selling, we are honored to help you through the
                    process!
                  </p>
                  <h3 className="mb-4 mt-5">Meet Brian Boals' Team</h3>
                  
                  <div className="row">
                    <div className="col-md-5 mb-4">
                      <LazyLoadImage
                        className="img-fluid"
                        effect="blur"
                        src="/img/jessica.jpg"
                      />
                    </div>
                    <div className="col-md-7">
                      <div className="intro mb-4">
                        <h3>Jessica Cartwright </h3>
                        <h5>Transaction Coordinator / Licensed Agent </h5>
                      </div>
                      <p>
                        Jessica has over 20 years of experience in the real
                        estate industry. She has continued to work through
                        changing markets, allowing her to gain a vast knowledge
                        of different real estate types, ranging from residential
                        sales to acquisitions. Jessica's career has focused
                        primarily on walking clients through the closing of
                        their transaction, from reviewing and writing offers to
                        the closing table! She truly enjoys the closing process,
                        and has an excellent eye for detail to ensure your
                        closing runs efficiently!
                      </p>
                    </div>
                  </div>
                  {/*<div className="row">
                    <div className="col-md-5 mb-4">
                      <LazyLoadImage
                        className="img-fluid"
                        effect="blur"
                        src="/img/ShariCiolli.jpg"
                      />
                    </div>
                    <div className="col-md-7">
                      <div className="intro mb-4">
                        <h3>Shari Ciolli</h3>
                      </div>
                      <p>
                        Shari offers over 15 years of real estate experience and
                        knowledge, having had an expansive background in the
                        Boulder market before making Colorado Springs her home
                        and community over 8 years ago. Shari’s diverse
                        experience began with preparing listings for market in a
                        mountain community and ranges from running both
                        marketing and contracts departments for a mid-size real
                        estate company, transaction coordination for real estate
                        teams and individuals, to the technology which makes it
                        all happen. Shari is here to offer her expertise and
                        thrives on offering excellent service and guiding you
                        through your listing process.
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default AboutPage
